<template>
  <div style="width: 1900px; height: 880px;">
      <i class="el-icon-loading" style="font-size: 30px; margin:20% auto; display: block;width: 30px; height: 30px"></i>
  </div>
</template>

<script>

export default {
  name:'',
  components: {},
  props: [],
  data () {
    return {}
  },
  mounted () {
      this.toPath()
  },
  computed: {},
  watch: {},
  methods: {
      toPath(){
          let viewRouter = JSON.parse(localStorage.getItem('viewRouters')).paths
          setTimeout(()=>{
              console.log('可见路由',viewRouter[1])
              this.$router.push({ path:viewRouter[1].path})
          },2000)
      }
  },
}
</script>
